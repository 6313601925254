"use client"
import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './CookiesBox.css';
import Link from 'next/link';
import { setCookie } from 'cookies-next';

const CookiesConsent = ({ cookiesConsent }) => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Delay added to ensure the animation is visible after the component mounts
    if (!cookiesConsent) {
      setTimeout(() => {
        setVisible(true);
      }, 100);
    }

  }, [cookiesConsent]);

  const handleAccept = () => {
    setCookie("cookies_consent", true, {
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      path: "/",
    })
    setVisible(false);
  };

  return (
    <Row>
      {visible &&
        <>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className={`cookies-consent ${visible ? 'visible' : ''}`}>
              <p>
                <span className='fw-semibold'>Cookie Preferences</span> - Mind Balance and its third-party advertising and analytics partners use cookies to provide a better experience for you. By using our site, you agree to the use of cookies as described in our &nbsp;
                <Link href={`/pages/privacy-policy`} className='text-white' target='_blank'>
                  Privacy Policy
                </Link> .
              </p>

              <div className="text-end">
                <Button variant="primary" onClick={handleAccept}>
                  Accept
                </Button>
              </div>

            </div>

          </Col>
          <Col xs={12} md={12} className="text-md-right text-center">
          </Col>
        </>
      }
    </Row>
  );
};

export default CookiesConsent;
